import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

// TODO key
@Component({
  selector: 'formly-field-date',
  template: `
    <ion-item lines="none">
      <ion-label>{{ props.label }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-datetime-button [datetime]="key.toString()"></ion-datetime-button>
      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime presentation="date"
                        showDefaultButtons="true"
                        [id]="key.toString()"
                        [formControl]="formControl"
                        [formlyAttributes]="field">
          </ion-datetime>
        </ng-template>
      </ion-modal>
    </ion-item>
  `,
})
export class FormlyDateTime extends FieldType<FieldTypeConfig> {
}
