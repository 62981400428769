import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Storage} from '@ionic/storage-angular';
import {ABILITIES_SUBJECTS, ABILITY_ACTIONS, Resident, User} from './user.types';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User;
  currentResident: Resident | null = null;
  canCreateTickets = false;
  canSetAnyStatus = false;

  constructor(private http: HttpClient,
              private storage: Storage) {
  }

  me(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }
    return this.http.get<User>('user/me').pipe(
      tap(user => {
        if (!this.user) {
          this.user = user;
          for (const role of this.user.roles) {
            role.permissions.forEach(permission => {
              if ((permission.action === ABILITY_ACTIONS.CREATE || permission.action === ABILITY_ACTIONS.MANAGE) && permission.subject === ABILITIES_SUBJECTS.TICKET) {
                this.canCreateTickets = true;
              }
              if (permission.action === ABILITY_ACTIONS.MANAGE && permission.subject === ABILITIES_SUBJECTS.TICKET_STATUS) {
                this.canSetAnyStatus = true;
              }
            });
          }
        }
      })
    );
  }

  getMyShops(): Observable<Resident[]> {
    return this.http.get<Resident[]>('/shop/my');
  }

  logout(): Observable<void> {
    return this.http.delete<void>('user/logout').pipe(
      tap(() => {
        this.storage.remove('x-access-token');
        this.user = null;
      })
    );
  }

  update(data: any): Observable<User> {
    return this.http.put<User>('user/me', data);
  }
}
