// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    input {
      border: none;
      width: 100%;
      outline: none;
    }
  `, "",{"version":3,"sources":["webpack://./src/app/shared/formly/masked-input.component.ts"],"names":[],"mappings":";IACI;MACE,YAAY;MACZ,WAAW;MACX,aAAa;IACf","sourcesContent":["\n    input {\n      border: none;\n      width: 100%;\n      outline: none;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
