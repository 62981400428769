import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy, isPlatform} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiInterceptorService} from './api-interceptor/api-interceptor.service';
import {IonicStorageModule} from '@ionic/storage-angular';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyTitle} from './shared/formly/title-field.component';
import {FormlyText} from './shared/formly/text-field.component';
import {SharedModule} from './shared/shared.module';
import {TicketTypesComponent} from './ticket-types/ticket-types.component';
import {AddTicketPage} from './ticket-types/add-ticket/add-ticket.page';
import {FormlyInput} from './shared/formly/input-field';
import {FormlyTextarea} from './shared/formly/textarea-field';
import {FormlyRepeatComponent} from './shared/formly/repeating-group-field';
import {FormlyGroupComponent} from './shared/formly/group-field';
import {FormlyTimePicker} from './shared/formly/timepicker.component';
import {FormlyDateTime} from './shared/formly/date-field';
import {TicketDetailsComponent} from './ticket-details/ticket-details.component';
import {QRCodeModule} from 'angularx-qrcode';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FormlyMaskedInputComponent } from './shared/formly/masked-input.component';
import { TicketTypeDescriptionComponent } from './ticket-types/ticket-type-description/ticket-type-description.component';
import { FormlySelectComponent } from './shared/formly/select';
import { FormlyItemModule } from './shared/formly-item/formly-item.module';

const apiInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiInterceptorService,
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    TicketTypesComponent,
    TicketDetailsComponent,
    AddTicketPage,
    TicketTypeDescriptionComponent,
    FormlyDateTime,
    FormlyGroupComponent,
    FormlyInput,
    FormlyRepeatComponent,
    FormlyText,
    FormlyTextarea,
    FormlyTimePicker,
    FormlyTitle,
    FormlyMaskedInputComponent,
    FormlySelectComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonDefaultHref: '/tabs/tickets',
      rippleEffect: true,
      mode: 'md',
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    SharedModule,
    FormlyModule.forRoot({
      types: [
        {name: 'title', component: FormlyTitle},
        {name: 'text', component: FormlyText},
        {name: 'datepicker', component: FormlyDateTime, wrappers: []},
        {name: 'input', component: FormlyInput, wrappers: []},
        {name: 'textarea', component: FormlyTextarea, wrappers: []},
        {name: 'group', component: FormlyGroupComponent, wrappers: []},
        {name: 'repeat', component: FormlyRepeatComponent, wrappers: []},
        {name: 'timepicker', component: FormlyTimePicker, wrappers: []},
        {name: 'mask', component: FormlyMaskedInputComponent, wrappers: []},
        {name: 'phone', component: FormlyMaskedInputComponent, wrappers: []},
        {name: 'select', component: FormlySelectComponent, wrappers: []},
      ],
    }),
    QRCodeModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FormlyModule,
    FormlyItemModule,
  ],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, apiInterceptor, provideNgxMask()],
  bootstrap: [AppComponent],
})
export class AppModule {
}
