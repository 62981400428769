import { Injectable } from '@angular/core';
import {Ticket, TicketDetails} from './tickets.types';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  public readonly reloadSubject: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {
  }

  get(id: string): Observable<TicketDetails> {
    return this.http.get<TicketDetails>(`/ticket/${id}`);
  }

  add(data): Observable<Ticket> {
    return this.http.post<Ticket>('/ticket', data);
  }

  update(id: string, data): Observable<Ticket> {
    return this.http.put<Ticket>(`/ticket/${id}`, data);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(`/ticket/${id}`);
  }

  getTicketsForRole(building: string): Observable<Ticket[]> {
    return this.http.get<Ticket[]>('/ticket/available', {
      params: {
        building,
      }
    });
  }

  getMyTickets(building: string): Observable<Ticket[]> {
    return this.http.get<Ticket[]>('/ticket/my', {
      params: {
        building,
      }
    });
  }

  addComment(id: string, comment: string): Observable<Ticket> {
    return this.http.post<Ticket>(`/ticket/${id}/comment`, {
      comment,
    });
  }

  uploadAttachment(ticketId: string, formData: any): Observable<any> {
    return this.http.post<any>(`/ticket/${ticketId}/attach`, formData);
  }

  changeStatus(ticketId: string, data): Observable<TicketDetails> {
    return this.http.post<TicketDetails>(`/ticket/${ticketId}/status`, data);
  }

  approveStatus(ticketId: string, roleId): Observable<TicketDetails> {
    return this.http.post<TicketDetails>(`/ticket/${ticketId}/approve`, {roleId});
  }
}
